<template>
  <div
    class="m-product-carousel-item"
    :class="{
      [`m-product-carousel-item--${variant}`]: variant,
      'm-product-carousel-item--out-of-stock': availability && !inStock,
    }"
  >
    <NuxtLink
      class="m-product-carousel-item__link"
      :to="getUrl(`products/${value.code}.html`, false)"
      @click.prevent="navigateToDetailPage"
    >
      {{ fullName.prefix }}{{ fullName.name }}
    </NuxtLink>
    <div class="m-product-carousel-item__content">
      <div class="m-product-carousel-item__img">
        <AImage
          :src="image.desktop"
          :mobile-src="image.mobile"
          width="290"
          height="240"
          with-retina
          with-webp
        />
        <!-- Badges -->
        <div
          v-if="discount && inStock || (productPackage?.itemsPerPack ?? 1) > 1"
          class="m-product-carousel-item__badges"
        >
          <ABadge
            v-if="discount && inStock"
            bevel-corner="right-bottom"
            class="badge-red"
          >
            -{{ discount }}%
          </ABadge>
          <ABadge
            v-if="(productPackage?.itemsPerPack ?? 1) > 1"
            color="brown"
            :bevel-corner="discount ? 'left-top' : 'right-bottom'"
            class="in-pack"
          >
            {{ productPackage?.pricePerItem?.amount ? $t('other.sold-in') : '' }}{{ productPackage?.itemsPerPack }} {{ $t("molecules.cart-product-item.pcs") }}
          </ABadge>
        </div>
      </div>

      <!-- Price -->
      <div class="m-product-carousel-item__price">
        <APrice
          :regular-price="productPackage?.pricePerItem?.amount ? productPackage.pricePerItem.amount : (productPricing?.price?.amount || 0)"
          :discount-price="productPackage?.pricePerItem?.amount ? 0 : (productPricing?.specialPrice?.price?.amount || 0)"
          :variant="productPricing?.specialPrice && inStock && !productPackage?.pricePerItem?.amount ? 'sale' : undefined"
          :currency="productPricing?.price?.currencyCodeIso"
          :with-count="!!productPackage?.pricePerItem?.amount"
        />
        <APriceone
          v-if="visiblePackagePrice"
          :price="visiblePackagePrice"
          :currency="productPricing?.price?.currencyCodeIso"
        />
      </div>

      <!-- Heading -->
      <LazyAProductTrigger
        v-if="value.feature"
        :text="$t('molecules.product-card.best-seller')"
      />
      <p class="m-product-carousel-item__title">
        {{ fullName.prefix }}{{ fullName.name }}
      </p>

      <!-- Description -->
      <MDescription
        v-if="region || Object.keys(characteristicsData)?.length"
        :region="region"
        :description="characteristicsData"
        size="sm"
      />

      <!-- Rating -->
      <div
        v-if="value.social?.rating || value.social?.reviewCount"
        class="m-product-carousel-item__rate"
      >
        <ARating :value="value.social?.rating || 0" />
        <span>{{ value.social?.reviewCount || 0 }}</span>
      </div>
    </div>

      <AFavorite
        :active="isFavoriteProduct"
        @toggle-favorite="$emit('toggle:favorite', value)"
      />

      <ABadge
        v-if="!inStock"
        color="grey-dark"
        size="sm"
        class="m-product-carousel-item__late"
      >
        {{ $t("molecules.product-card.late") }}
      </ABadge>

      <!-- Experts -->
      <div
        v-if="value.expertRatesList.length !== 0"
        class="m-product-carousel-item__experts"
      >
        <MProductExpert
          v-for="(expert, index) in slicedExperts"
          :key="`${expert?.code}-${index}`"
          :expert="expert"
          :expert-info="ratingsDescriptions[expert.code]"
          variant="rounded"
          @open:expert="getExpertInfo"
        />
      </div>

    <!-- Cart -->
    <div
      v-if="inStock"
      class="m-product-carousel-item__cart"
    >
      <AButton
        v-if="!productCounter"
        :loading="loadingId === value.id"
        @click.stop="addToCard(value, unit)"
      >
        {{ $t("atoms.button.add-to-cart") }}
      </AButton>
      <ClientOnly v-else>
        <LazyAInputNumber
          :model-value="productCounter"
          :max="availableQuantity"
          :unit="unit"
          @update:model-value="setCartProductAmount({ id: value.id, amount: $event })"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, defineComponent } from 'vue'
import { useCartStore } from '@/stores/cart'
import { useProductManager, sizes } from '@/composables/product'
import { useNavigate } from '@/composables/useNavigate'
import { useExpertsStore } from '@/stores/experts'

import type { PropType } from 'vue'
import type { Availability } from '@/modules/nuxt-api/models/Product'
import type { Product } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'

// Atoms
import AButton from '@/components/atoms/Button/AButton.vue'
import AFavorite from '@/components/atoms/Favorite/AFavorite.vue'
import AImage from '@/components/atoms/Image/AImage.vue'
import ARating from '@/components/atoms/Rating/ARating.vue'
import { useProducts } from '@/stores/products'
import APrice from '@/components/atoms/Price/APrice.vue'
import APriceone from '@/components/atoms/Priceone/APriceone.vue'
import ABadge from '@/components/atoms/Badge/ABadge.vue'

// Molecules
import MDescription from '@/components/molecules/Description/MDescription.vue'
import MProductExpert from '@/components/molecules/ProductExpert/MProductExpert.vue'

const LazyAInputNumber = defineAsyncComponent(() => /* @vite-ignore */ import('@/components/atoms/InputNumber/AInputNumber.vue'))
const LazyAProductTrigger = defineAsyncComponent(() => /* @vite-ignore */ import('@/components/atoms/ProductTrigger/AProductTrigger.vue'))

export type ProductCarouselItemVariant = 'similar' | 'special-offer'

defineComponent({ name: 'MProductCarouselItem' })

const props = defineProps({
  value: {
    type: Object as PropType<Product.AsObject>,
    required: true,
    default: () => ({})
  },
  variant: {
    type: String as PropType<ProductCarouselItemVariant>,
    default: undefined
  },
  isFavorite: Boolean,
  metaAnchor: {
    type: String,
    default: undefined
  },
  availability: {
    type: Object as PropType<Availability>,
    default: null
  }
})

const emit = defineEmits(['toggle:favorite', 'set:detailCurrentProduct'])
const { packagesMap, pricesMap } = useProducts()
const { getUrl, navigatePush } = useNavigate()
const cartStore = useCartStore()
const { addProductToCart, setCartProductAmount, loadingId } = cartStore
const productManager = useProductManager()

const region = computed(() => productManager.getProductRegion(props.value))
const inStock = computed(() => props.availability
  ? props.availability?.isAvailable
  : true
)
const availableQuantity = computed(() => inStock.value
  ? props.availability?.availableRemains || 999
  : 0
)

const isFavoriteProduct = computed(() => process.client ? props.isFavorite : false)

const fullName = computed(() => productManager.formatName(
  props.value?.name,
  false,
  '',
  '',
  props.metaAnchor
))
const productPackage = computed<Product.AsObject['pb_package'] | Record<string, never>>(() => {
  if (!props.value) {
    return {}
  }

  const { pb_package, id } = props.value

  return packagesMap.value[id] ?? pb_package ?? {}
})
const productPricing = computed(() => {
  if (!props.value) {
    return {}
  }

  const { pricing, id } = props.value

  return pricesMap.value[id] ?? pricing ?? {}
})
const productCounter = computed<number>(() => process.server
  ? 0
  : cartStore.itemsList.value.find(el => el.product?.id === props.value.id)?.quantity ?? 0
)
const unit = computed(() => productPackage.value?.pricePerItem?.amount ? productPackage.value?.itemsPerPack ?? 1 : 1)
const discount = computed(() => productPricing.value?.specialPrice?.discountAmount)

const image = computed(() => {
  const img = props.value?.imagesList?.[0]?.url ?? ''
  const { mobile, desktop } = sizes.carousel
  return {
    mobile: img.replace('%w', mobile.w).replace('%h', mobile.h),
    desktop: img.replace('%w', desktop.w).replace('%h', desktop.h)
  }
})

const characteristicsData = computed(() => productManager.formatData(
  'grid',
  props.value.category,
  props.value?.characteristicsMap?.filter(item => item[1])
))
const navigateToDetailPage = (): void => {
  emit('set:detailCurrentProduct', props.value)
  navigatePush(`products/${props.value.code}.html`, false)
}

// Package price to show
const visiblePackagePrice = computed(() => {
  if (unit.value === 1 && productPackage.value?.itemsPerPack && productPackage.value.itemsPerPack > 1) {
    const totalPrice = Number(productPricing.value?.price?.amount ?? 0)
    return Math.round(totalPrice / productPackage.value.itemsPerPack)
  }

  return undefined
})

// Experts
const ratingsDescriptions = ref<any>({})
const slicedExperts = computed(() => props.value.expertRatesList?.slice(0, 3) ?? [])
const { getRatingDescription } = useExpertsStore()

const getExpertInfo = async (code: string): Promise<void> => {
  ratingsDescriptions.value[code] = await getRatingDescription(code)
}

function addToCard (product: Product.AsObject, unit: number) {
  addProductToCart(product.id, unit)
}
</script>

<style lang="postcss">
.m-product-carousel-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: var(--spacer-3xs);
  background: var(--color-white);

  &__content {
    margin-bottom: var(--spacer-xs);
  }

  &__link {
    position: absolute;
    z-index: 1;
    opacity: 0;
    inset: 0;
  }

  &--out-of-stock {
    .m-product-carousel-item__img {
      opacity: 0.5;
    }
  }

  & .product-expert > div:first-child > div:last-child {
    transform: none;
  }

  &__img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacer-sm);
    mix-blend-mode: multiply;

    & > .image img {
      object-fit: contain;
      height: 240px;
    }
  }

  & > .favorite {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  &__badges {
    position: absolute;
    bottom: calc(-1 * var(--spacer-4xs));
    left: 0;
    display: flex;
    flex-wrap: wrap-reverse;
    row-gap: var(--spacer-4xs);
    align-items: flex-start;
    justify-content: flex-start;

    & .badge-red {
      @mixin text-sm-bold-italic;
    }
  }

  &__cart {
    z-index: 2;
  }

  &__experts {
    position: absolute;
    top: var(--spacer-3xs);
    left: 0;
    z-index: 2;
    display: grid;
    gap: var(--spacer-4xs);

    & .product-expert {
      width: max-content;
    }
  }

  & .badge + &__experts {
    top: var(--spacer-xl);
  }

  &__price {
    margin-bottom: var(--spacer-4xs);
  }

  &__title {
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: var(--spacer-3xs);
    color: var(--color-neutral-900);
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @mixin text-sm-semibold;

    @media (hover: hover) and (--screen-lg) {
      &:hover {
        color: var(--color-blue-800);
      }
    }
  }

  &__late.badge {
    position: absolute;
    top: var(--spacer-3xs);
  }

  & .info-description {
    max-height: var(--fs-7xl);

    ul {
      &::after {
        content: '';
        position: absolute;
        top:0;
        right: 0;
        bottom: 0;
        width: var(--info-description-gradient-width);
        background: linear-gradient(90deg, rgb(255 255 255 / 0%) 1.79%, #FFF 49.36%);
      }
    }

    & > .info-description__row {
      white-space: nowrap;
    }
  }

  &__rate {
    display: inline-flex;
    gap: var(--spacer-4xs);
    align-items: center;
    margin-top: var(--spacer-3xs);
    color: var(--color-neutral-600);

    & span {
      display: flex;
      gap: 0 var(--spacer-4xs);
      align-items: center;
      border-bottom: 0;

      @mixin text-sm;
    }
  }

  & > div:last-child {
    margin-top: auto;

    & > .button {
      padding: 0 var(--spacer-xs);
    }
  }
}

@media (--screen-xs) {
  .m-product-carousel-item {
    width: 8.5rem;
    min-width: 8.5rem;

    &__img,
    &--similar .m-product-carousel-item__img {
      margin-bottom: var(--spacer-xs);

      & > .image img {
        height: 9.5rem;
      }
    }

    &__badges {
      bottom: 0;
      flex-wrap: wrap-reverse;
      row-gap: var(--spacer-4xs);

      & .badge-red,
      & .in-pack {
        @mixin text-xs-bold;
      }

      & .badge-red,
      & .in-pack:first-child {
        padding: 0 0 0 var(--spacer-3xs);
      }

      & .in-pack {
        padding: 0 var(--spacer-3xs) 0 0;
        white-space: nowrap;
      }
    }
  }
}

.m-product-carousel-item--similar {
  & > div:last-child {
    display: none;
  }

  & > a {
    margin-bottom: 0;
  }

  & .m-product-carousel-item__img {
    margin-bottom: var(--spacer-xs);

    & img {
      height: 8.75rem;
    }
  }
}

.m-product-carousel-item--special-offer {
  & .info-description {
    display: none;
  }

  & .m-product-carousel-item__img {
    margin-bottom: var(--spacer-xs);

    & img {
      height: 9.75rem;
    }
  }

  &__badges {
    bottom: 0;
  }

  @media (--screen-lg) {
    & > a {
      margin-bottom: 0;
    }
  }
}

@media (--screen-lg) {
  .m-product-carousel-item--special-offer {
    & > div:last-child {
      display: none;
    }
  }

  @media (hover: hover) {
    .m-product-carousel-item > a:hover .m-product-carousel-item__title {
      color: var(--color-blue-800);
    }
  }
}
</style>
